const getData = () => {
    let url = "https://backend.quokkalabs.com/api/get-blogs";
    fetch(url,{
        headers: {
            "IdentityToken": 'CWbY2nofDJFIT98WtgZnTpx6OIbnDH9sxuiabUHlcfs=',
            "Access-Control-Allow-Origin": "*"
        },
    }).then((response) => {
        return response.json();
    }).then((data) => {
        data.data.slice(0, 4).map((ele)=>{  
        const node = document.createElement("div");
        node.classList.add('swiper-slide');      
        let content = `<div class="mb-2 scale-100 hover:scale-105 transition-all duration-500 hover:shadow-md ">
                        <div class="mb-2">
                            <a href=${ele.post_link} target="_blank"><img src=${ele.banner_image} alt="${ele.title.slice(0, 50)}" class="w-full" style="height:180px"/></a>
                        </div>
                        <div class="p-2">
                            <p class="text-xs text-slate-800 mb-2 Gilroy-Medium subPara">${ele.author}</p>
                            <p class="mb-2 text-black Gilroy-Bold text-sm headingFive">${ele.title.slice(0, 50)}</p>
                            <a href=${ele.post_link} target="_blank" class="capitalize font-bold Gilroy-Regular text-violet-900 block mb-2 text-xs subPara">Read More</a>
                        </div>
                    </div>`;
        node.innerHTML = content;
        document.getElementById("blogBox").appendChild(node);
        })
    }).catch(err => console.log(err))
}
getData();

// career data section
const career = ()=>{
    let url = "https://backend.quokkalabs.com/api/get-positions";
    fetch(url,{
        headers: {
            "IdentityToken": 'CWbY2nofDJFIT98WtgZnTpx6OIbnDH9sxuiabUHlcfs=',
            "Access-Control-Allow-Origin": "*"
        },
    }).then((response) => {
        return response.json();
    }).then((data) => {
        data.data.slice(0, 6).map((ele)=>{  
        const node = document.createElement("div");
        node.style.cursor ='pointer';
        node.classList.add('bg-violet');
        let content = `<div class="w-full p-8  hover:bg-violet-400 transition duration-150 ease-out hover:ease-in relative border border-violet-900/10" id="view-detail${ele.id}">
                      <h3 class="text-lime-300 font-bold">${ele.title}</h3>
                        <p class="mb-4">${ele.exp}<span class="text-right float-right top-9 absolute right-10 text-sm Gilroy-Regular">02 jan 2022</span>
                        </p>
                        <div><p>${ele.intro.slice(0, 160) + " . . ."}</p></div>
                        <div class="flex justify-end uppercase text-sm" >view detail</div>
                    </div>`;
        node.innerHTML = content;
        document.getElementById("position_box").appendChild(node);
        initializeEvent(ele.id)
        })
        
    })
}
career();


function initializeEvent(id){
    let viewELe= document.getElementById('view-detail'+id);
    viewELe.addEventListener('click',function(){
        let url = new URL("https://quokkalabs.com/portfolio-career.html");
        url.searchParams.append('id',id);
        window.open(url.href,'_self');
    })
}


// designation or career apply section
function getPortfolioById(){
    var baseUrl = (window.location).href;
    var koopId = baseUrl.substring(baseUrl.lastIndexOf('=') + 1);
    getPositionById(koopId)
}
getPortfolioById()

function getPositionById(id){
    let url=`https://backend.quokkalabs.com/api/get-position/${id}` 
    let job_title = document.getElementById('designation_title');
    let experience = document.getElementById('experience');
    let introduction = document.getElementById('introduction');
    let skill_title = document.getElementById('skill_title');
    let role_title = document.getElementById('role_title');

    fetch(url,{
        headers: {
            "IdentityToken": 'CWbY2nofDJFIT98WtgZnTpx6OIbnDH9sxuiabUHlcfs='
        },
    }).then((response)=>{
        return response.json();
    }).then((data_portfolio)=>{
        job_title.innerHTML = data_portfolio.data.title;
        experience.innerHTML = data_portfolio.data.exp;
        introduction.innerHTML = data_portfolio.data.intro;

        data_portfolio.data.desired_skillset.forEach((el)=>{
            let li = document.createElement("li"); 
            li.classList.add('listTpyo');
            li.innerHTML = el;
            document.getElementById('job_skill').appendChild(li);  
        });

        data_portfolio.data.job_description.forEach((el)=>{
            let li = document.createElement("li"); 
            li.classList.add('listTpyo');
            li.innerHTML = el;
            document.getElementById('job_description').appendChild(li);  
        });

        skill_title.innerHTML =  data_portfolio?.data?.desired_skillset.length!==0 ?  "Desired Skillset" : "";
        role_title.innerHTML =  data_portfolio?.data?.job_description.length!==0 ?  "Roles & Responsiblities" : "";
    })

    
    
}


